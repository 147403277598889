/**
 * Created by boris on 3/17/17.
 */

import axiosClient from '../axiosClient'

import {
  MAIN_URL,
  SET_ADD_STORY_PAGE,
  FORBIDDEN,
  SET_REQUEST_SENT,
} from '../actions/constants'

export function setAddStoryPage(page) {
  return {
    type: SET_ADD_STORY_PAGE,
    page,
  }
}

export function setRequestSent() {
  return {
    type: SET_REQUEST_SENT,
  }
}

export function postAddStoryPage(url) {
  return dispatch => {
    dispatch(setRequestSent())
    return axiosClient
      .post(MAIN_URL + '/stories/add_page ', { url })
      .then(response => {
        dispatch(setAddStoryPage(response.data))
      })
      .catch(err => {
        if (err.response.status === FORBIDDEN) dispatch(setAddStoryPage())
      })
  }
}

export function postAddStoryPageWithMetadata({
  url,
  image,
  description,
  title,
  site,
}) {
  return dispatch => {
    dispatch(setRequestSent())
    return axiosClient
      .post(MAIN_URL + '/stories/add_page ', {
        url,
        image,
        description,
        title,
        site,
      })
      .then(response => {
        dispatch(setAddStoryPage(response.data))
      })
      .catch(err => {
        if (err.response.status === FORBIDDEN) dispatch(setAddStoryPage())
      })
  }
}

export function submitAddStory(data) {
  return dispatch => {
    return axiosClient
      .post(MAIN_URL + '/stories', data)
      .then(response => {
        dispatch(setAddStoryPage())
      })
      .catch(err => {
        if (err.response.status === FORBIDDEN) dispatch(setAddStoryPage())
      })
  }
}
